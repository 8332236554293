
@import "base";
@import "fonts";
@import "confetti";

html,
body  {
    @apply h-full;
    @apply font-kobalt;

    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.link-gradient {
    background: linear-gradient(90deg, rgba(242,244,249,0) 0%, rgba(242,244,249,1) 88%);
}

.preload * {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
}

.blend-mode-difference {
  mix-blend-mode: difference;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.image-offset  {
  position: relative;
  // transform: rotate3d(0.7,-0.866,0,-25deg) rotateZ(-1deg);
  filter: drop-shadow(-27.1px 62.5px 125px #F3F6F9);
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 48px 96px rgba(5, 19, 50, 0.3));
  border-radius: 15px;
  -webkit-transform: rotateX(1deg) rotateY(-5deg) rotateZ(-1deg) translateX(2px) translateY(-2px) translateZ(2px) scale(0.9) skewX(-2deg) skewY(1deg);
  -moz-transform: rotateX(1deg) rotateY(-5deg) rotateZ(-1deg) translateX(2px) translateY(-2px) translateZ(2px) scale(0.9) skewX(-2deg) skewY(1deg);
  -ms-transform: rotateX(1deg) rotateY(-5deg) rotateZ(-1deg) translateX(2px) translateY(-2px) translateZ(2px) scale(0.9) skewX(-2deg) skewY(1deg);
  -o-transform: rotateX(1deg) rotateY(-5deg) rotateZ(-1deg) translateX(2px) translateY(-2px) translateZ(2px) scale(0.9) skewX(-2deg) skewY(1deg);
  transform:  rotateX(1deg) rotateY(-5deg) rotateZ(-1deg) translateX(2px) translateY(-2px) translateZ(2px) scale(0.9) skewX(-2deg) skewY(1deg);  
}

@responsive {
  .invert {
    filter: invert(1)
  }
}

@tailwind base;

@tailwind components;

@tailwind utilities;
