.container {
    max-width: 1200px !important;
    @screen tablet {
      padding: 0 !important;
    }
}

input::placeholder {
    @apply text-13;
    @apply font-light;
    @apply text-dark-gray;
}

.image-shadow {
    filter: drop-shadow(0px 24px 48px rgba(5, 19, 50, 0.1));
}
