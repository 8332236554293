@font-face {
    font-family: Kobalt;
    src: url("/fonts/kobalt/Kobalt-Regular.otf") format('otf'),
        url('/fonts/kobalt/Kobalt-Regular.woff2') format('woff2'),
        url('/fonts/kobalt/Kobalt-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Kobalt;
    src: url("/fonts/kobalt/Kobalt-Bold.otf") format('otf'),
        url('/fonts/kobalt/Kobalt-Bold.woff2') format('woff2'),
        url('/fonts/kobalt/Kobalt-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Inter;
    src: url("/fonts/inter/Inter-Regular.ttf") format('ttf'),
        url('/fonts/inter/Inter-Regular.woff2') format('woff2'),
        url('/fonts/inter/Inter-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: Inter;
    src: url("/fonts/inter/Inter-Bold.ttf") format('ttf'),
        url('/fonts/inter/Inter-Bold.woff2') format('woff2'),
        url('/fonts/inter/Inter-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
